
export const authorizationFeatureKey = 'authorization';

export const authorizationPersistKeys = [
  'token',
  'loggedUserName',
].map(value => `${authorizationFeatureKey}.${value}`);

export interface AuthorizationState {
  token: string | undefined;
  loggedUserName: string | undefined;
  loading: boolean,
}

export const initialAuthorizationState: AuthorizationState = {
  token: undefined,
  loading: false,
  loggedUserName: undefined,
};
